import { Box } from 'grommet';
import styled from 'styled-components';

export const FullHeightBox = styled(Box)(props => ({
  backgroundRepeat: 'repeat',
  minHeight: '100vh',
  padding: `20px`,
  ...props.sx,
}));

// const sx={
//   backgroundImage: `url(${
//     customBackground ? customBackground : background
//   })`,
//   backgroundRepeat: `repeat`,
//   minHeight: `100vh`,
//   padding: 20,
//   ...sx,
// };

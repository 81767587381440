var data = {
  body: `<g transform="matrix(0.744798,0,0,0.744798,1.3843,-14.896)" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
  <path id="path3156" d="M65.412,155.072C65.412,155.072 90.08,133.597 163.089,133.597C230.067,133.597 248.89,148.791 268.007,155.072L168.271,203.807L65.412,155.072Z" style="fill:rgb(201,120,31);fill-rule:nonzero;" />
  <g id="g3158" transform="matrix(0.639262,0,0,0.639262,-21.0391,-84.8748)">
    <path id="path3160" d="M118.946,476.458C119.653,491.03 134.21,484.288 140.804,479.732C147.063,475.407 148.893,479.002 149.442,470.466C149.802,464.856 150.449,459.246 150.13,453.613C140.666,452.755 130.371,455.009 122.612,460.646C118.616,463.551 111.122,472.82 118.946,476.458" style="fill:rgb(246,210,162);fill-rule:nonzero;" />
    <path id="path3162" d="M118.946,476.458C121.065,475.67 123.31,475.11 124.748,473.194" style="fill:rgb(198,177,152);fill-rule:nonzero;" />
    <path id="path3164" d="M152.588,302.861C96.804,287.174 138.284,216.207 183.08,245.397L152.588,302.861Z" style="fill:rgb(55,171,200);fill-rule:nonzero;" />
    <path id="path3166" d="M400.436,240.071C444.591,209.057 484.492,279.03 433.176,296.636L400.436,240.071Z" style="fill:rgb(55,171,200);fill-rule:nonzero;" />
    <path id="path3168" d="M409.934,655.8C421.15,662.74 441.65,683.723 424.825,693.898C408.659,708.7 399.611,677.651 385.422,673.349C391.533,665.051 399.278,657.484 409.934,655.8Z" style="fill:rgb(246,210,162);fill-rule:nonzero;" />
    <path id="path3170" d="M424.825,693.897C422.331,688.937 421.493,683.149 417.329,679.151" style="fill:none;fill-rule:nonzero;" />
    <path id="path3172" d="M209.561,679.514C196.397,681.551 188.987,693.428 178.013,699.459C167.672,705.625 163.716,697.485 162.784,695.832C161.163,695.093 161.299,696.52 158.797,694.001C149.21,678.871 168.786,667.812 178.979,660.296C193.177,657.425 202.075,669.734 209.561,679.514Z" style="fill:rgb(246,210,162);fill-rule:nonzero;" />
    <path id="path3174" d="M162.785,695.831C163.286,690.065 167.859,686.203 170.036,681.327" style="fill:none;fill-rule:nonzero;" />
    <path id="path3176" d="M154.916,283.26C147.556,279.367 142.157,274.08 146.659,265.567C150.827,257.687 158.57,258.542 165.93,262.435L154.916,283.26Z" style="fill:rgb(7,126,145);fill-rule:nonzero;" />
    <path id="path3178" d="M421.549,275.859C428.909,271.966 434.308,266.679 429.806,258.166C425.638,250.285 417.896,251.141 410.535,255.034L421.549,275.859Z" style="fill:rgb(7,126,145);fill-rule:nonzero;" />
    <path id="path3180" d="M472.21,474.607C471.503,489.179 456.946,482.437 450.352,477.881C444.093,473.556 442.263,477.151 441.714,468.616C441.354,463.006 440.707,457.396 441.026,451.763C450.49,450.905 460.785,453.159 468.544,458.796C472.54,461.7 480.034,470.97 472.21,474.607" style="fill:rgb(246,210,162);fill-rule:nonzero;" />
    <path id="path3182" d="M472.21,474.607C470.091,473.819 467.846,473.259 466.408,471.343" style="fill:rgb(198,177,152);fill-rule:nonzero;" />
    <path id="path3186" d="M289.988,210.595C345.835,210.595 398.188,218.582 425.48,272.237C449.976,332.378 441.265,397.23 445.001,460.79C448.209,515.367 455.323,578.419 430.004,628.995C403.369,682.205 336.813,695.59 281.978,693.629C238.907,692.088 186.877,678.036 162.569,638.685C134.05,592.52 147.552,523.875 149.623,472.506C152.077,411.657 133.141,350.624 153.131,291.081C173.868,229.316 229.796,215.357 289.988,210.595" style="fill:rgb(55,171,200);fill-rule:nonzero;" />
    <path id="path3188" d="M299.847,285.567C309.874,343.855 405.151,328.444 391.466,269.657C379.195,216.941 296.515,231.533 299.847,285.567" style="fill:white;fill-rule:nonzero;" />
    <path id="path3190" d="M185.992,294.994C198.988,345.739 280.232,332.747 277.17,281.845C273.501,220.881 173.567,232.645 185.992,294.994" style="fill:white;fill-rule:nonzero;" />
    <path id="path3192" d="M318.343,353.511C318.387,361.301 320.186,368.914 318.632,377.659C316.697,381.315 312.903,381.702 309.631,383.179C305.107,382.469 301.303,379.499 299.488,375.267C298.327,366.065 299.921,357.156 300.214,347.951L318.343,353.511Z" style="fill:white;fill-rule:nonzero;" />
    <g id="g3194">
      <ellipse id="ellipse3196" cx="208.4" cy="286.718" rx="13.719" ry="14.86" />
      <ellipse id="ellipse3198" cx="214.64" cy="290.071" rx="3.234" ry="3.777" style="fill:white;" />
    </g>
    <g id="g3200">
      <ellipse id="ellipse3202" cx="323.348" cy="283.017" rx="13.491" ry="14.86" />
      <ellipse id="ellipse3204" cx="329.485" cy="286.371" rx="3.181" ry="3.777" style="fill:white;" />
    </g>
    <path id="path3206" d="M279.137,354.685C273.151,369.192 282.475,398.2 298.716,376.804C297.555,367.602 299.149,358.693 299.442,349.488L279.137,354.685Z" style="fill:white;fill-rule:nonzero;" />
    <path id="path3210" d="M278.185,326.748C267.029,327.699 257.909,340.964 263.71,351.458C271.392,365.358 288.538,350.228 299.217,351.646C311.508,351.898 321.578,364.642 331.45,353.95C342.429,342.058 326.723,330.476 314.448,325.298L278.185,326.748Z" style="fill:rgb(246,210,162);fill-rule:nonzero;" />
  </g>
  <g id="g3212" transform="matrix(0.639262,0,0,0.639262,-21.0391,-84.8748)">
    <path id="path3214" d="M135.236,375.349C135.236,375.349 138.768,413.61 138.526,430.103C138.284,446.595 154.086,436.182 154.906,462.119C155.726,488.056 145.334,483.137 139.859,495.832C134.384,508.526 130.663,567.977 130.663,567.977C130.663,567.977 136.281,579.085 153.829,589.087C171.377,599.089 199.594,604.707 224.027,603.6C248.46,602.493 265.912,597.259 270.276,594.364C274.639,591.47 281.881,582.314 284.605,575.233C287.329,568.152 293.02,509.831 292.623,476.458C292.226,443.085 286.712,412.292 286.712,412.292L135.236,375.349Z" style="fill:rgb(239,147,37);fill-rule:nonzero;" />
    <path id="path3216" d="M290.007,537.841C218.055,545.873 134.568,523.669 134.568,523.669C134.568,523.669 135.794,501.148 141.194,493.222C216.027,516.407 291.523,509.056 291.523,509.056C292.643,519.57 291.854,527.928 290.007,537.841Z" style="fill:rgb(229,229,229);fill-rule:nonzero;" />
    <path id="path3218" d="M280.95,582.228C208.998,590.26 130.663,567.164 130.663,567.164C130.663,567.164 130.412,545.533 133.27,536.034C208.103,559.219 288.033,553.441 288.033,553.441C287.36,564.854 285.755,576.087 280.95,582.228Z" style="fill:rgb(229,229,229);fill-rule:nonzero;" />
  </g>
  <g id="g3220" transform="matrix(0.639262,0,0,0.639262,-21.0391,-84.8748)">
    <path id="path3222" d="M452.156,375.349C452.156,375.349 451.668,413.61 451.913,430.103C452.158,446.595 442.335,436.182 441.504,462.119C440.673,488.056 451.196,483.137 456.74,495.832C462.284,508.526 466.052,567.977 466.052,567.977C466.052,567.977 460.363,579.085 442.595,589.087C424.827,599.089 396.255,604.707 371.515,603.6C346.775,602.493 329.104,597.259 324.685,594.364C320.266,591.469 312.934,582.314 310.176,575.233C307.418,568.152 301.655,509.831 302.057,476.458C302.459,443.085 308.042,412.292 308.042,412.292L452.156,375.349Z" style="fill:rgb(239,147,37);fill-rule:nonzero;" />
    <path id="path3224" d="M304.706,537.841C377.562,545.873 462.098,523.669 462.098,523.669C462.098,523.669 460.856,501.148 455.389,493.222C379.615,516.407 303.171,509.056 303.171,509.056C302.037,519.57 302.836,527.928 304.706,537.841Z" style="fill:rgb(229,229,229);fill-rule:nonzero;" />
    <path id="path3226" d="M313.876,582.228C386.732,590.26 466.051,567.164 466.051,567.164C466.051,567.164 466.305,545.533 463.411,536.034C387.637,559.219 306.704,553.441 306.704,553.441C307.386,564.854 309.011,576.087 313.876,582.228Z" style="fill:rgb(229,229,229);fill-rule:nonzero;" />
  </g>
  <g id="g3228" transform="matrix(0.639262,0,0,0.639262,-21.0391,-84.8748)">
    <path id="path3230" d="M481.221,445.525C478.957,449.797 474.476,451.859 471.212,450.129L468.597,448.744C465.333,447.015 464.521,442.149 466.785,437.877L517.77,341.643C520.033,337.371 524.515,335.309 527.779,337.039L530.394,338.424C533.658,340.153 534.47,345.019 532.206,349.291L481.221,445.525Z" style="fill:rgb(210,226,97);fill-rule:nonzero;" />
    <path id="path3232" d="M457.143,490.972C455.824,493.461 452.109,494.077 448.845,492.347L446.23,490.962C442.966,489.233 441.388,485.813 442.707,483.324L466.627,438.175C467.946,435.686 471.661,435.07 474.925,436.8L477.54,438.185C480.804,439.914 482.382,443.334 481.063,445.823L457.143,490.972Z" style="fill-rule:nonzero;" />
    <path id="path3234" d="M478.411,436.54L475.796,435.155C472.532,433.426 469.192,433.332 468.337,434.945L466.808,437.831C467.663,436.217 471.002,436.311 474.267,438.041L476.882,439.426C480.146,441.155 482.1,443.866 481.245,445.479L482.774,442.593C483.629,440.98 481.675,438.27 478.411,436.54Z" style="fill:rgb(155,155,155);fill-rule:nonzero;" />
  </g>
  <g id="ellipse3236" transform="matrix(0.599116,0.222975,-0.222975,0.599116,100.483,-168.172)">
    <ellipse cx="456.838" cy="462.661" rx="11.224" ry="8.683" style="fill:rgb(246,210,162);" />
  </g>
  <g id="g3238" transform="matrix(0.639262,0,0,0.639262,-21.0391,-84.8748)">
    <path id="path3240" d="M111.779,447.078C113.844,451.45 118.226,453.715 121.566,452.137L124.241,450.873C127.581,449.295 128.615,444.472 126.55,440.1L80.032,341.629C77.967,337.257 73.585,334.992 70.245,336.57L67.57,337.834C64.23,339.412 63.196,344.235 65.261,348.607L111.779,447.078Z" style="fill:rgb(210,226,97);fill-rule:nonzero;" />
    <path id="path3242" d="M133.748,493.582C134.951,496.129 138.634,496.914 141.975,495.337L144.65,494.073C147.99,492.495 149.723,489.151 148.519,486.604L126.694,440.405C125.491,437.858 121.808,437.073 118.467,438.65L115.792,439.914C112.452,441.492 110.719,444.836 111.923,447.383L133.748,493.582Z" style="fill-rule:nonzero;" />
    <path id="path3244" d="M114.998,438.232L117.673,436.968C121.013,435.39 124.353,435.449 125.133,437.1L126.528,440.054C125.748,438.403 122.408,438.344 119.068,439.921L116.393,441.185C113.053,442.763 110.977,445.381 111.757,447.032L110.362,444.078C109.583,442.428 111.658,439.81 114.998,438.232Z" style="fill:rgb(155,155,155);fill-rule:nonzero;" />
  </g>
  <g id="ellipse3246" transform="matrix(-0.599116,0.222975,-0.222975,-0.599116,252.768,458.953)">
    <ellipse cx="137.56" cy="463.922" rx="11.224" ry="8.683" style="fill:rgb(246,210,162);" />
  </g>
  <g id="g3248" transform="matrix(0.639262,0,0,0.639262,-21.0391,-84.8748)">
    <g id="g3250">
      <path id="path3252" d="M159.132,324.732C156.914,328.765 151.682,330.146 147.445,327.816L142.572,325.137C138.335,322.807 136.699,317.649 138.916,313.616L189.63,221.386C191.848,217.353 197.08,215.972 201.317,218.302L206.19,220.981C210.427,223.311 211.52,227.67 209.846,232.502C192.081,283.76 159.132,324.732 159.132,324.732Z" style="fill:rgb(150,0,0);fill-rule:nonzero;" />
      <path id="path3254" d="M172.547,272.051C187.969,244.004 198.102,219.882 195.688,217.146L195.745,217.043C195.679,217.007 195.609,216.983 195.543,216.947C195.535,216.942 195.533,216.926 195.524,216.921L195.517,216.935C171.818,204.094 139.934,216.811 124.183,245.457C108.432,274.103 114.773,307.838 138.311,320.971L138.304,320.985C138.313,320.99 138.327,320.983 138.336,320.987C138.402,321.024 138.459,321.07 138.525,321.106L138.582,321.003C142.184,321.576 157.125,300.098 172.547,272.051Z" style="fill:rgb(89,89,89);fill-rule:nonzero;" />
    </g>
    <g id="g3256">
      <path id="path3258" d="M426.693,324.925C428.793,329.02 433.984,330.552 438.286,328.345L443.234,325.807C447.536,323.601 449.321,318.492 447.221,314.397L399.19,220.742C397.09,216.647 391.899,215.115 387.597,217.322L382.649,219.86C378.347,222.066 377.128,226.393 378.662,231.27C394.941,283.019 426.693,324.925 426.693,324.925Z" style="fill:rgb(150,0,0);fill-rule:nonzero;" />
      <path id="path3260" d="M414.804,271.879C400.198,243.399 390.765,218.994 393.257,216.329L393.203,216.224C393.27,216.19 393.341,216.168 393.408,216.134C393.417,216.129 393.419,216.113 393.428,216.109L393.435,216.123C417.494,203.971 448.998,217.603 463.916,246.692C478.834,275.781 471.522,309.319 447.615,321.767L447.622,321.781C447.613,321.785 447.599,321.778 447.59,321.782C447.523,321.817 447.465,321.862 447.398,321.896L447.344,321.791C443.726,322.259 429.41,300.359 414.804,271.879Z" style="fill:rgb(89,89,89);fill-rule:nonzero;" />
    </g>
    <path id="path3262" d="M462.36,259.869C462.36,259.869 444.614,215.423 424.034,191.924C403.454,168.426 202.097,160.412 169.031,191.924C135.965,223.436 123.498,259.869 123.498,259.869L123.498,250.025C123.498,250.025 138.793,206.757 169.031,182.08C199.269,157.403 397.977,158.702 423.613,182.08C449.248,205.458 462.36,250.025 462.36,250.025L462.36,259.869Z" style="fill:rgb(53,53,53);fill-rule:nonzero;" />
    <g id="ellipse3264" transform="matrix(-0.8898,0.4563,-0.4563,-0.8898,986.533,258.198)">
      <ellipse cx="462.092" cy="248.211" rx="6.949" ry="16.975" style="fill:rgb(150,0,0);" />
    </g>
    <g id="ellipse3266" transform="matrix(0.8763,0.4818,-0.4818,0.8763,134.612,-30.1261)">
      <ellipse cx="125.962" cy="247.029" rx="6.949" ry="16.975" style="fill:rgb(150,0,0);" />
    </g>
  </g>
  <g id="g3268" transform="matrix(0.639262,0,0,0.639262,-21.0391,-84.8748)">
    <path id="path3270" d="M386.49,250.492L349.285,250.492L319.791,250.492L274.555,250.492L249,250.492L198.68,250.492C164.96,250.492 165.076,340.098 196.735,345.263L262.201,341.053C270.116,341.053 285.92,310.398 288.885,307.988C291.85,305.578 301.614,305.284 305.46,307.988C309.306,310.692 318.053,337.964 325.967,337.964L391.433,345.262C422.012,331.19 414.303,250.492 386.49,250.492Z" style="fill:white;fill-opacity:0.6;fill-rule:nonzero;" />
    <path id="path3272" d="M248.545,269.019C227.86,268.557 195.495,268.745 177.816,268.889C174.852,278.608 171.547,290.913 173.186,302.861C189.242,302.861 224.146,302.939 248.968,302.939C263.914,302.939 276.48,305.759 288.166,308.814C288.454,308.446 288.696,308.175 288.885,308.022C291.85,305.612 301.614,305.318 305.46,308.022C306.455,308.722 307.783,311.08 309.334,314.171C315.301,315.429 321.277,316.298 327.487,316.376C344.402,316.59 384.044,316.522 409.553,316.45C412.802,305.593 410.406,293.955 409.678,283.018C384.772,283.018 345.191,282.456 326.215,282.456C299.477,282.455 276.132,269.635 248.545,269.019Z" style="fill:white;fill-opacity:0.5;fill-rule:nonzero;" />
  </g>
  <path id="path3274" d="M155.896,123.631C155.368,111.276 178.925,109.731 181.707,120.073C184.482,130.392 157.056,132.788 155.896,123.631C154.971,116.32 155.896,123.631 155.896,123.631Z" style="fill-rule:nonzero;" />
</g>`,
  width: 256,
  height: 256,
};

export default data;

var data = {
  body: `<g transform="matrix(1.14298,0,0,1.14298,-21.4464,-26.8621)" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
  <g transform="matrix(1.024,0,0,1.024,-3.13802,-3.25174)">
      <path d="M240.114,49.546C240.114,43.817 235.462,39.165 229.733,39.165L31.769,39.165C26.04,39.165 21.388,43.817 21.388,49.546L21.388,221.432C21.388,227.162 26.04,231.813 31.769,231.813L229.733,231.813C235.462,231.813 240.114,227.162 240.114,221.432L240.114,49.546Z" style="fill:rgb(238,238,238);"/>
  </g>
  <path d="M240.114,49.546C240.114,43.817 235.462,39.165 229.733,39.165L31.769,39.165C26.04,39.165 21.388,43.817 21.388,49.546L21.388,221.432C21.388,227.162 26.04,231.813 31.769,231.813L229.733,231.813C235.462,231.813 240.114,227.162 240.114,221.432L240.114,49.546Z" style="fill:rgb(32,42,47);"/>
  <g transform="matrix(0.874902,0,0,0.874902,18.7635,23.5017)">
      <path d="M35.346,48.044L35.346,37.981L45.26,37.981L45.26,48.025C52.616,49.357 56.078,53.206 56.702,54.063L49.806,62.227C47.508,59.928 43.942,57.709 39.503,57.709C35.382,57.709 33.242,59.849 33.242,62.781C33.242,66.031 36.175,67.774 41.168,70.073L45.051,71.816C54.086,75.938 58.207,79.266 58.207,86.795C58.207,93.849 53.557,100.36 44.257,102.267L44.257,111.813L34.343,111.813L34.343,102.682C25.955,101.732 21.161,97.939 18.977,95.989L25.555,87.112C27.932,89.332 33.084,92.977 39.503,92.977C44.021,92.977 46.24,90.917 46.24,88.063C46.24,84.893 43.704,83.546 37.601,80.772L32.846,78.632C24.604,74.987 21.196,70.073 21.196,63.732C21.196,55.324 27.315,49.668 35.346,48.044Z" style="fill:rgb(14,232,39);fill-rule:nonzero;"/>
  </g>
  <rect x="82.515" y="56.729" width="32.6" height="64.603" style="fill:rgb(15,116,30);"/>
</g>`,
  width: 256,
  height: 256,
};

export default data;

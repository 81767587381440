import React, { useContext } from 'react';
import {
  Box,
  Heading,
  Paragraph,
  Anchor,
  ResponsiveContext,
  Text,
  Button,
  Image,
} from 'grommet';
import { lighten } from 'polished';
import { Helmet } from 'react-helmet';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Seo from '../components/seo';
import { FullHeightBox } from '../components/FullHeightBox';
import { getThemeColor } from '../theme';
import Layout from '../components/Layout';
import styled from 'styled-components';

import contactMe from '../images/illustrations/contact_envelope.svg';
import backpackIllustration from '../images/illustrations/backpack.svg';
import helloIllustration from '../images/illustrations/hello.svg';

import itermIcon from '../custom-icons/iterm2';
import javascriptIcon from '@iconify/icons-simple-icons/javascript';
import typescriptIcon from '@iconify/icons-simple-icons/typescript';
import reactIcon from '@iconify/icons-logos/react';
import vscodeIcon from '@iconify/icons-logos/visual-studio-code';
import dockerIcon from '@iconify/icons-logos/docker-icon';
import gatsbyIcon from '@iconify/icons-logos/gatsby';
import htmlIcon from '@iconify/icons-logos/html-5';
import cssIcon from '@iconify/icons-logos/css-3';
import graphqlIcon from '@iconify/icons-logos/graphql';
import postgresqlIcon from '@iconify/icons-logos/postgresql';
import jestIcon from '@iconify/icons-logos/jest';
import cypressIcon from '@iconify/icons-logos/cypress';
import mongodbIcon from '@iconify/icons-simple-icons/mongodb';
import expressIcon from '../custom-icons/express';
import ramdaIcon from '../custom-icons/ramda';
import traefikIcon from '../custom-icons/traefik';
import droneIcon from '@iconify/icons-simple-icons/drone';
import clippyIcon from '@iconify/icons-octicon/clippy';
import githubIcon from '@iconify/icons-octicon/mark-github';
import { Icon } from '@iconify/react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const StackItem = ({ icon, iconColor, iconBackground, description, title }) => (
  <Box direction="row">
    <Box
      css={`
        min-width: 4rem;
        margin: 0 24px 24px 0;
      `}
    >
      <Icon
        icon={icon}
        color={iconColor || 'inherit'}
        css={`
          display: flex;
          width: 4rem;
          height: 4rem;
          background-color: ${iconBackground ? iconBackground : 'transparent'};
        `}
      />
    </Box>
    <Box
      css={`
        margin: 0 0 24px 0;
      `}
    >
      {title && (
        <Text
          css={`
            font-weight: 600;
          `}
        >
          {title}
        </Text>
      )}
      {description && (
        <Text
          size="1rem"
          color={lighten(0.5, getThemeColor('brand-dark-blue'))}
        >
          {description}
        </Text>
      )}
    </Box>
  </Box>
);

const Overlay = styled(Box)`
  text-align: center;
  margin: 0px auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 100vh;
`;

const InternalLink = styled(Link)`
  box-sizing: border-box;
  font-size: inherit;
  line-height: inherit;
  color: #00adb5;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  outline: none;

  &:hover {
    text-decoration: underline;
  }
`;

const IndexPage = () => {
  const deviceSize = useContext(ResponsiveContext);

  return (
    <Layout>
      <Seo title="Welcome" />
      <Helmet>
        <link rel="canonical" href="https://lukashorak.com" />
      </Helmet>
      <FullHeightBox
        align="center"
        css={`
          position: relative;
          width: 100%;
          padding: 0;
          height: calc(100vh + ${deviceSize === 'small' ? '5px' : '50px'});
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5rem));
        `}
        justify="center"
      >
        <StaticImage
          src="../images/index_bg_dark_hires.jpg"
          alt="Lukas Horak hero background image"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: "110vh",
            zIndex: -1
          }}
          objectFit='cover'
          objectPosition={'50% 50%'}
        />
        <Overlay>
          <Box as={'section'} id="intro" align="center" width="large">
            <Heading
              textAlign="center"
              css={{ color: getThemeColor('brand-light-gray') }}
              level={1}
            >
              Hello, I'm&nbsp;
              <span css={{ color: getThemeColor('brand'), display: `inline` }}>
                Lukas&nbsp;Horak
              </span>
              .
              <br />I am a full-stack JavaScript developer.
            </Heading>
            <Heading
              size={'small'}
              textAlign="center"
              css={{ color: 'white' }}
              level={2}
            >
              I build apps using React on front-end, and Node.js on backend.
            </Heading>
          </Box>
        </Overlay>
      </FullHeightBox>
      <Box align="center" pad="large">
        <Box
          as={'section'}
          justify="center"
          direction={deviceSize === 'small' ? 'column' : 'row'}
          width="xxlarge"
          alignSelf="center"
          align="center"
        >
          <Box
            width="large"
            pad="medium"
            basis="2/5"
            css={`
              max-width: ${deviceSize === 'small' ? '80%' : '30%'};
            `}
            align="center"
          >
            <Image
              src={helloIllustration}
              fill={false}
              a11yTitle="Hello Illustration"
              alt="Hello Illustration"
            />
          </Box>
          <Box direction="column" pad="medium">
            <Heading id="about-me" textAlign="center">
              Short introduction
            </Heading>
            <Box justify="around" direction="column" width="large">
              <Box>
                <Paragraph fill>
                  Hey there! Let me introduce myself again, my name is Lukas,
                  and I am a full-stack JavaScript developer. I have over 6
                  years of experience developing websites and web applications.
                  I enjoy working on both front-end and backend, but I work
                  mostly on front-end part of apps. My go to library on
                  front-end is React, and NodeJS on backend.
                </Paragraph>
                <Paragraph fill>
                  Right now, I'm working as a front-end lead at{' '}
                  <Anchor
                    href="https://securityscorecard.com"
                    rel="noopener noreferrer"
                    a11yTitle="SecurityScorecard website"
                    target="_blank"
                  >
                    SecurityScorecard
                  </Anchor>
                  , building a platform that allows companies to monitor their
                  security threats within their online presence or security of
                  their vendors.
                </Paragraph>
                <Paragraph fill>
                  If you'd like, you can{' '}
                  <InternalLink to="/about-me">
                    read more about me here
                  </InternalLink>
                  , or scroll to see my development backpack (in other words the
                  technologies I use daily).
                </Paragraph>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        align="center"
        pad="large"
        css={`
          background: ${getThemeColor('brand-dark-blue')};
          color: ${getThemeColor('brand-light-gray')};
          clip-path: polygon(0rem 0rem, 100% 5rem, 100% 100%, 0 100%);
          padding-top: 6rem;
        `}
      >
        <Box width="small">
          <Image
            src={backpackIllustration}
            fill={false}
            alt="Development Backpack Illustration"
            a11yTitle="Development Backpack Illustration"
          />
        </Box>
        <Heading level="2" textAlign="center">
          My development backpack
        </Heading>
        <Box direction={deviceSize === 'small' ? 'column' : 'row'}>
          <Box
            direction="column"
            basis={deviceSize === 'small' ? '100%' : '1/3'}
            pad="small"
          >
            <Heading level="3">Languages</Heading>
            <StackItem
              icon={javascriptIcon}
              iconColor="#F0DB4F"
              title="JavaScript"
              description="My go to programming language pretty much for everything. Be it the front-end, backend, or a CLI tool."
            />
            <StackItem
              icon={typescriptIcon}
              iconColor="#007ACC"
              title="TypeScript"
              description="I use TypeScript whenever I can, it has a great complier and type safety is the way to go!"
            />
            <StackItem
              icon={htmlIcon}
              title="HTML"
              description="Mostly writing HTML in JSX, but from time to time I find myself writing a plain, classic HTML file."
            />
            <StackItem
              icon={cssIcon}
              title="CSS"
              description="Styling of html elements; I usually go for styled-components or Sass."
            />
          </Box>
          <Box
            direction="column"
            basis={deviceSize === 'small' ? '100%' : '1/3'}
            pad="small"
          >
            <Heading level="3">Libraries and Frameworks</Heading>
            <StackItem
              icon={reactIcon}
              title="React"
              description="My favourite framework for building web front-ends and single page apps. I've been working with AngularJS before, but after trying React, I wouldn't go back."
            />
            <StackItem
              icon={gatsbyIcon}
              title="Gatsby"
              description="Great framework for building blazing fast websites. By the way, this website is using Gatsby under the hood!"
            />
            <StackItem
              icon={jestIcon}
              title="Jest"
              description="Great tool for unit tests."
            />
            <StackItem
              icon={cypressIcon}
              title="Cypress"
              description="When writing integration tests or e2e tests, I use Cypress, great debugging. Unfortunately Cypress runs in Chrome only, so no cross browser testing capabilities. For cross browser testing, I usually go with Testcafe or Selenium."
            />
            <StackItem
              icon={graphqlIcon}
              title="GraphQL"
              description="Still just playing and exploring, but I think the concept of GraphQL is very well thought out."
            />
            <StackItem
              icon={expressIcon}
              iconColor={getThemeColor('brand-light-gray')}
              title="Express"
              description="When creating HTTP servers or APIs, I usually go for the combination of NodeJS and Express. Lightweight, easily extensible framework to build APIs and web servers."
            />
            <StackItem
              icon={ramdaIcon}
              iconColor={getThemeColor('brand-light-gray')}
              title="Ramda"
              description="Useful library to write functional JavaScript code."
            />
          </Box>
          <Box
            direction="column"
            basis={deviceSize === 'small' ? '100%' : '1/3'}
            pad="small"
          >
            <Heading level="3">Tools and Others</Heading>
            <StackItem
              icon={dockerIcon}
              title="Docker"
              description="I try to dockerize all the apps that I'm either using or building."
            />
            <StackItem
              icon={droneIcon}
              iconColor={getThemeColor('brand-light-gray')}
              title="Drone CI"
              description="Found this Docker-first CI, and using it on my custom projects. Very easy to set up and run. Everything Dockerized!"
            />
            <StackItem
              icon={githubIcon}
              iconColor={getThemeColor('brand-light-gray')}
              title="GitHub"
              description="Where I store all my git repositories..."
            />
            <StackItem
              icon={postgresqlIcon}
              iconColor={getThemeColor('brand-light-gray')}
              title="PostgreSQL"
              description="When I need relational database..."
            />
            <StackItem
              icon={mongodbIcon}
              iconColor="#4DB33D"
              title="MongoDB"
              description="When I need a noSQL database, or just quickly prototyping..."
            />
            <StackItem
              icon={traefikIcon}
              iconColor="#4DB33D"
              title="traefik"
              description="Great HTTP proxy and load balancer. Great support for Docker containers."
            />
            <StackItem
              icon={vscodeIcon}
              title="Visual Studio Code"
              description="My favourite code editor."
            />
            <StackItem
              icon={itermIcon}
              title="iTerm2"
              description="The best terminal app for MacOS!"
            />
          </Box>
        </Box>
        <Box pad="medium">
          <Box>
            <Text size="large" textAlign="center">
              These are what I use the most... Would you like to see more of my
              dev stack?
            </Text>
          </Box>
          <Box pad="medium">
            <Button
              href="https://stackshare.io/lhorak"
              alt="Lukas Horak's stack on Stackshare.io"
              a11yTitle="Lukas Horak's stack on Stackshare.io"
              target="_blank"
              rel="noopener"
              color={getThemeColor('brand')}
              label="Go to my stackshare profile"
              css={`
                background: ${getThemeColor('brand')};
                padding: 1rem 2rem;
                text-align: center;
                font-weight: 700;
              `}
            />
          </Box>
        </Box>
      </Box>
      <Box align="center" pad="large">
        <Box width="medium">
          <Image
            src={contactMe}
            width="large"
            fill={false}
            alt="Contact Me Illustartion"
            a11yTitle="Contact Me Illustartion"
          />
        </Box>
        <Heading level="1">Have a question?</Heading>
        <Text textAlign="center" size="large">
          Drop me a line at:
        </Text>
        <Text textAlign="center" size="large">
          <Anchor
            href="mailto:hello@lukashorak.com"
            alt="hello@lukashorak.com"
            a11yTitle="hello@lukashorak.com"
          >
            hello@lukashorak.com
          </Anchor>
          <CopyToClipboard text="hello@lukashorak.com">
            <Button
              icon={
                <Icon
                  icon={clippyIcon}
                  height="1rem"
                  color={getThemeColor('brand-gray')}
                  css={`
                    margin-left: 0.5rem;
                  `}
                />
              }
              plain
              a11yTitle="Copy email to clipboard"
            />
          </CopyToClipboard>
        </Text>
      </Box>
    </Layout>
  );
};

export default IndexPage;
